html,
body,
#root {
  height: 100%;
  width: 100%;
  background: #b2d2a4;
}

body {
  font-family: system-ui;
  margin: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

.flex {
  display: flex;
  align-items: center;
}

.flex.fill {
  height: 100%;
}

.flex.center {
  justify-content: center;
}

button {
  cursor: pointer;
}