.bottomnav {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    border-top: 1px solid rgb(230, 230, 230);
    background-color: #b2d2a4;
}
.bntab {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.base {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
}